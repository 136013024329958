import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // openId: window.sessionStorage.getItem('openId'),
    companyId: window.sessionStorage.getItem('companyId'),
    token: window.sessionStorage.getItem('token'),
  },
  mutations: {
    // SET_OPEN_ID (state, data) { //用户id
    //   state.openId = data;
    //   window.sessionStorage.setItem('openId', data);
    // },
    SET_COMPANY_ID (state, data) { //设置companyId
      state.companyId = data;
      window.sessionStorage.setItem('companyId', data);
    },
    SET_TOKEN (state, data) { //设置token
      state.token = data;
      window.sessionStorage.setItem('token', data);
    },
    LOGIN_OUT (state) { //登出
      // state.openId = null;
      state.companyId = null;
      state.token = null;
      // window.sessionStorage.removeItem('openId');
      window.sessionStorage.removeItem('companyId');
      window.sessionStorage.removeItem('token');
    }
  },
  actions: {

  }
});
