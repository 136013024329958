<template>
    <div class="body">
        <el-form :rules="rules" ref="loginForm" :model="loginForm" class="loginContainer">
            <h3 class="loginTitle">
                欢迎登录
            </h3>
            <el-form-item prop="username">
                <el-input type="text" v-model="loginForm.username" placeholder="请输入用户名" >
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input type="password" v-model="loginForm.password" placeholder="请输入密码" >
                </el-input>
            </el-form-item>
            <!--<el-checkbox v-model="checked" class="loginRemember">记住我</el-checkbox>-->
            <el-button type="primary" style="width:100%" @click="submitLogin">登录</el-button>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return{
                captchaUrl: "",
                loginForm: {
                    username: "",
                    password: ""
                },
                // checked: true,
                rules:{
                    username:[
                        {required:true,message:"请输入用户名",trigger:"blur"}
                    ],
                    password:[
                        {required:true,message:"请输入密码",trigger:"blur"}
                    ],
                }

            }
        },
        methods: {
            submitLogin(){
                this.$refs.loginForm.validate((valid) => {
                    if (valid) {
                        let data = {
                            "name": this.loginForm.username,
                            "password": this.loginForm.password,
                        }
                        if(this.loginForm.username == 'admin' && this.loginForm.password == 'admin_portal!'){
                            this.$apiService.setLogin(data).then(res => {
                              this.$message({
                                message: '登录成功',
                                type: 'success'
                              });
                              let companyId = 2;
                              this.$store.commit('SET_COMPANY_ID', companyId);
                              this.$store.commit('SET_TOKEN', res.token);
                              this.$router.push("/");
                            })
                        }else{
                            this.$message.error('用户名或密码错误，请重新输入');
                        }

                    } else {
                        // this.$message.error('登录出错请重新输入');
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .body{
        width: 100%;
        height: 100%;
        background: #ccc;
        background: url(../assets/bg-login.jpg) no-repeat;
        background-position: center bottom;
        background-size: cover;
    }
    .loginContainer{
        border-radius: 15px;
        background-clip: padding-box;
        text-align: left;
        width: 350px;
        padding: 25px 35px 30px;
        background: rgba(255,255,255,.3);
        border: 1px solid #ccc;
        box-shadow: 0 0 5px #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -80%);
    }
    .loginTitle{
        margin: 0 auto 20px;
        text-align: center;
        font-size: 40px;
        color: #fff;
    }
</style>
