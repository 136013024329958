import axios from 'axios'
import { Message } from 'element-ui'

const interfaceData = {
    /* 公司查询-企业 */
    getCompanyQuery(paramsData) {
        return apiAxios('POST', '/v1/web/company/query', paramsData);
    },

    /* 客户查询/客户列表 - 用户 */
    getClientQuery(paramsData) {
        return apiAxios('POST', '/v1/web/client/query', paramsData);
    },

    /* 获取token */
    setLogin(paramsData) {
        return apiAxios('POST', '/v1/web/login', paramsData);
    },

    /* 导出客户 */
    exportClient() {
        return apiAxios('GET', '/v1/web/client/export');
    },
    /* 导出企业 */
    exportCompany() {
        return apiAxios('GET', '/v1/web/company/export');
    },
    /* 导出记录查询 */
    exportSearchRecord() {
        return apiAxios('GET', '/v1/web/search_record/export');
    }
};

function apiAxios(method, httpUrl, params) {
    let token = window.sessionStorage.getItem('token');
    // let openId = window.sessionStorage.getItem('openId');
    // params.open_id = openId;

    let httpDefault = {
        method: method,
        url: 'https://api.stage.kpayunion.com'+httpUrl,
        headers: {
            "token": token ? token : ''
        },
        params: method === 'GET' || method === 'DELETE' ? params : null,
        data: method === 'POST' || method === 'PUT' ? params : null
    };
    return new Promise((resolve,reject) => {
        axios(httpDefault).then(res => {
            // console.log(res.data)
            if(res.data.code === 0){
                resolve(res.data.data);
            }else{ //后台返回的错误信息
                Message.error(res.data.message);
            }
        },
        err => { //http返回的错误信息
            reject(err);
            Message.error('网络请求错误');
        })
    })
}

export default interfaceData;
