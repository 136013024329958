<template>
    <el-container>
        <el-header>
            <div class="user-data">
                <h4>admin</h4>
                <el-button type="primary" plain class="btn" @click="exitBtn()">退出</el-button>
            </div>
        </el-header>

        <el-tabs v-model="activeName" type="card" @tab-click="tabsClick">
            <el-tab-pane label="用户列表" name="tab1">
                <div class="flex">
                    <el-input v-model="inputYH" placeholder="请输入内容"></el-input>
                    <el-button type="primary" data-input="inputYH" @click="submitBtn">搜索</el-button>
                    <el-button type="primary" data-id="tableDataYH" data-name="用户" @click="exportBtn">导出</el-button>
                </div>
                <el-table
                        id="tableDataYH"
                        :data="tableDataYH"
                        style="width: 100%">
                    <el-table-column label="姓名" prop="name" align="center"></el-table-column>
                    <el-table-column label="身份证" prop="card_number" align="center"></el-table-column>
                    <el-table-column label="状态" prop="date" align="center">
                        <template slot-scope="scope">
                            <el-tag size="medium" type="success" v-if="scope.row.status == 1">正常</el-tag>
                            <el-tag size="medium" type="danger" v-if="scope.row.status == 2">拒单</el-tag>
                            <el-tag size="medium" type="danger" v-if="scope.row.status == 3">逾期</el-tag>
                            <el-tag size="medium" v-if="scope.row.status == 0">被查询</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="备注" prop="comment" align="center"></el-table-column>
                    <el-table-column label="时间" prop="created_at" align="center"></el-table-column>

                </el-table>
            </el-tab-pane>

            <el-tab-pane label="企业列表" name="tab2">
                <div class="flex">
                    <!--<el-input v-model="inputQY" placeholder="请输入内容"></el-input>-->
                    <!--<el-button type="primary" data-input="inputQY" @click="submitBtn">搜索</el-button>-->
                    <el-button type="primary" data-id="tableDataQY" data-name="企业" @click="exportBtn">导出</el-button>
                </div>
                <el-table
                        id="tableDataQY"
                        :data="tableDataQY"
                        style="width: 100%">
                    <el-table-column label="公司名称" prop="name" align="center"></el-table-column>
                    <el-table-column label="公司地址" prop="address" align="center"></el-table-column>
                    <el-table-column label="联系电话" prop="phone" align="center"></el-table-column>
                    <el-table-column label="入驻状态" prop="verified" align="center">
                        <template slot-scope="scope">
                            <el-tag size="medium" type="success" v-if="scope.row.verified == 1">已入驻</el-tag>
                            <el-tag size="medium" type="danger" v-else>未入驻</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="时间" prop="created_at" align="center"></el-table-column>
                </el-table>

                <el-pagination
                    style="margin-top: 30px;"
                    @size-change="setChangeQY"
                    @current-change="setCurrentChangeQY"
                    :current-page="currentPageQY"
                    :page-sizes="[10, 20, 30]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalQY">
                </el-pagination>
            </el-tab-pane>

            <el-tab-pane label="查询记录" name="tab3">
                <div class="flex">
                    <el-button type="primary" data-id="recordList" data-name="查询记录" @click="exportBtn">导出</el-button>
                </div>
                <el-table
                        id="recordList"
                        :data="recordList"
                        style="width: 100%">
                    <!--<el-table-column label="序号" prop="id" align="center"></el-table-column>-->
                    <el-table-column label="姓名" prop="name" align="center"></el-table-column>
                    <el-table-column label="身份证" prop="card_number" align="center"></el-table-column>
                    <el-table-column label="状态" prop="status" align="center"></el-table-column>
                    <el-table-column label="公司名称" prop="company_name" align="center"></el-table-column>
                    <el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
                    <el-table-column label="备注" prop="comment" align="center"></el-table-column>

                </el-table>
            </el-tab-pane>
        </el-tabs>
    </el-container>
</template>

<script>
    import exportExecl from "../api/exportExecl.js"
    export default {
        name: "List",
        data() {
            return {
                activeName: 'tab1',
                tableDataYH: [],
                tableDataQY: [],
                recordList: [],
                inputYH: '',
                // inputQY: '',
                currentPageQY: 1, // 页码
                totalQY: 0,
            }
        },
        created() {
            this.getUserList();
            this.getCompanyQuery(this.currentPageQY);
            this.getRecordBtn();
        },
        methods: {
            tabsClick(tab, event) {
                console.log(tab, event);
            },
            submitBtn(e){ //筛选
                let inputVal = e.currentTarget.getAttribute("data-input")
                if(inputVal == 'inputYH' && this.inputYH){
                  this.getUserList(this.inputYH)
                }
                else{
                  console.log("请输入筛选条件")
                }
                // else if (inputVal == 'inputQY'){
                //   this.getCompanyQuery(this.inputQY)
                // }

            },
            exportBtn(e) { //导出
                let btnId = e.currentTarget.getAttribute("data-id")
                let btnName = e.currentTarget.getAttribute("data-name")

                if(btnId == 'tableDataQY'){
                    this.getExportQYList(btnId, btnName);
                }
                else{
                    exportExecl(btnId, btnName+"列表.xlsx")
                }
            },
            getExportQYList (btnId, btnName) {
                let timer = null;
                clearTimeout(timer);
                this.$apiService.exportCompany().then(res => {
                    this.tableDataQY = res.list;
                    timer = setTimeout(() => {
                        exportExecl(btnId, btnName+"列表.xlsx")

                        this.getCompanyQuery(this.currentPageQY);
                    }, 1000)
                });
            },

            setChangeQY(val) {
                console.log(`每页 ${val} 条`);
            },
            setCurrentChangeQY(val) {
                console.log(val);
                this.getCompanyQuery(val)
            },

            getRecordBtn() { // 记录
              this.$apiService.exportSearchRecord().then(res => {
                this.recordList = res.list;
              });
            },
            getUserList(input) {
              let data = {
                "name": "",
                "page": 0,
                "card_num": "",
                condition: input
              }
              this.$apiService.getClientQuery(data).then(res => {
                this.tableDataYH = res.list;
              });
            },
            getCompanyQuery(page) {
              let data = {
                "name": "",
                "page": page,
                "phone": "",
                "condition": ''
              }
              this.$apiService.getCompanyQuery(data).then(res => {
                this.tableDataQY = res.list;
                this.totalQY = res.count;
              });
            },
            exitBtn() {
              this.$message({
                message: '退出成功',
                type: 'success'
              });
              this.$store.commit('LOGIN_OUT');
              this.$router.push("/login");
            },
        }
    }
</script>

<style scoped lang="less">
    .el-container{
        padding: 0 0 50px;
    }
    .flex{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 20px;
    }
    .el-header{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: #409EFF;
        background: rgba(3, 18, 54,.8)
    }
    .el-input,
    .el-button{
        margin-right: 10px;
    }
    .user-data{
        display: flex;
        align-content: center;
        justify-content: flex-end;
    }
    .user-data h4{
        margin: 0;
        padding: 0;
        line-height: 30px;
        color: #fff;
        font-weight: bold;
    }
    .user-data .btn{
        padding: 0 10px;
        height: 30px;
        line-height: 30px;
        margin-left: 20px;
    }
    .user-data .btn span{
        display: block;
    }

    .el-tabs{
        padding: 30px 100px 0;
    }
</style>
