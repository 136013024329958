import Vue from "vue";
import VueRouter from "vue-router";
import List from "../views/List";
import Login from "../views/Login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "List",
    component: List,
    // meta: {
    //   needLogin: true
    // }
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('token');
  if (token) {
    if (to.path === '/login') {
      next({ path: '/' })
    }else {
      next();
    }
  } else {
    if (to.path !== '/login') {
      next({ path: '/login' })
    } else {
      next()
    }
  }
})

export default router;
