import FileSaver from 'file-saver'
const XLSX = require('xlsx')

/**
 * el-table数据导出execl文件
 *
 * @param {*} id:el-table元素id
 * @param {*} fileName :导出文件名称
 * @returns
 */
function exportExecl(id,fileName){
  let excelName = fileName||'导出表格.xlsx';
  var xlsxParam = { raw: true };
  let tables=document.getElementById(id);
  if(!tables) return
  tables = document.getElementById(id).cloneNode(true);
  // 移除固定列，防止重复生成表格
  if (tables.querySelector('.el-table__fixed') !== null) {
    tables.removeChild(tables.querySelector('.el-table__fixed'))
  }
  let table_book = XLSX.utils.table_to_book(tables,xlsxParam);
  var table_write = XLSX.write(table_book, {
    bookType: "xlsx",
    bookSST: true,
    type: "array"
  });
  try {
    FileSaver.saveAs(
      new Blob([table_write], { type: "application/octet-stream" }),
      excelName
    );
  } catch (e) {
    console.log(e, table_write);
  }
  return table_write;
}
export default exportExecl
